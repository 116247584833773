.panel-pair-image {
    height: 100%;
    background-size: cover;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.panel-image-link-wrap {
    width: 50%;
    height: 35vh;
    position: relative;
    z-index: 5;


    &::before {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: #00000033;
        content: "";
        transition: 1s all ease-in-out;
    }

    &:hover {
        &::before {
            background-color: #c678da33;
            transition: 1s all ease-in-out;
        }
    }

    @include MQ(M) {
        width: 100%;
        margin: 0;
        margin-bottom: 2rem;
    }
}

.panel-pair {
    padding-bottom: 100px;
    width: 100%;

    @include MQ(M) {
        flex-direction: column;
    }
}

.panel-pair-content {
    color: white;
    font-size: 2rem;
    font-weight: bold;
    position: relative;

    img {
        max-width: 60%;
        height: auto;
        margin: auto;
    }
}
