/* Page Loader */
.js .loading::before,
.js .loading::after {
	content: '';
	z-index: 1000;
}

.js .loading::before {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--color-bg);
}

.js .loading::after {
	top: 50%;
	left: 50%;
	width: 60px;
	height: 60px;
	margin: -30px 0 0 -30px;
	border-radius: 50%;
	opacity: 0.4;
	background: var(--color-link);
	animation: loaderAnim 0.7s linear infinite alternate forwards;

}

@keyframes loaderAnim {
	to {
		opacity: 1;
		transform: scale3d(0.5,0.5,1);
	}
}

.frame {
	padding: 3rem 5vw;
	text-align: center;
	position: relative;
	z-index: 1000;
	color: var(--color-frame);
}

.frame a {
	color: var(--color-frame);
}

.frame__title {
	font-size: 1.25rem;
	margin: 0 0 1rem;
}

.frame__title span {
	position: absolute;
	pointer-events: none;
	opacity: 0;
}

.frame__links {
	display: inline;
}

.frame__links a:not(:last-child),
.frame__demos a:not(:last-child) {
	margin-right: 1rem;
}

.frame__demos {
	margin: 1rem 0;
}

a.frame__demo--current,
a.frame__demo--current:hover {
	color: white;
}

.slideshow {
	width: 100vw;
	height: calc(100vh - 13rem);
	position: relative;
	overflow: hidden;
}

.slide {
	margin: 0;
	background: color(slate);
}

.slide,
.slide__img-wrap,
.slide__img {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.slide {
	display: flex;
	align-items: center;
	justify-content: center;
}

.js .slide {
	opacity: 0;
	pointer-events: none;
}

.js .slide--current {
	opacity: 1;
	pointer-events: auto;
}

.slide__img-wrap {
	will-change: transform;
	overflow: hidden;

	&::after {
		position: absolute;
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		background-color: black;
		opacity: 0.4;
	}
}

.slide__img {
	background-size: cover;
	will-change: transform;
	-webkit-backspace-visibility: hidden;
}

.slide__caption {
	position: relative;
	padding: 0 10vw;
	cursor: default;
	width: 100%;
}

.slides__caption-headline {
	font-size: 6.5vw;
	font-size: clamp(2rem,6.5vw,4rem);
	margin: 0;
	font-weight: normal;
}

.text-row {
	position: relative;
	display: block;
	overflow: hidden;
	white-space: wrap;
	max-width: 70%;
}

.text-row > span {
	display: block;
	position: relative;
	padding: 0.5rem 0;
}

.slides__caption-headline {
	font-weight: 300;
}

.slides__caption-headline em,
.slides__caption-headline strong {
	font-size: 6.75vw;
	font-size: clamp(1.6rem,7vw,2rem);
}

.slides__caption-headline em {
	font-weight: 300;
	font-style: normal;
	color: color(light-grey);
}

.slides__caption-headline strong {
	font-weight: 400;
}

.slides__caption-link {
	display: inline-block;
	margin-top: 1rem;
	text-indent: 0.2vw;
	font-size: 1.2rem;
	font-weight: 300;
	font-size: clamp(1.4rem, 5vw, 2rem);
	color: color(light-grey);
}

.slides-nav {
	display: flex;
	align-items: center;
	justify-content: center;
}

.slides-nav__button {
	display: block;
	cursor: pointer;
	background: none;
	border: 0;
	width: 55px;
	height: 24px;
	padding: 0;
	margin: 0 0.75rem;
	-webkit-appearance: none;
	-moz-appearance: none;
}

.slides-nav__button:focus {
	outline: none;
}

.slides-nav svg {
	display: block;
	width: 100%;
	height: 100%;
	fill: #fff;
}

.slides-nav__index {
	margin-left: 2rem;
	white-space: nowrap;
	color: var(--color-text);
	border: 1px solid var(--color-text);
	border-radius: 50%;
	padding: 1rem 1.5rem;
	text-align: center;
	display: flex;
	align-items: center;
}

.slides-nav__index > span {
	width: 2rem;
}

.slides-nav__index-current {
	position: relative;
	overflow: hidden;
}

.slides-nav__index-current span {
	display: inline-block;
}

@media screen and (min-width: 53em) {
	.frame {
		position: absolute;
		text-align: left;
		z-index: 100;
		top: 0;
		left: 0;
		display: grid;
		align-content: space-between;
		width: 100%;
		max-width: none;
		height: 100vh;
		padding: 2rem 3.5rem;
		pointer-events: none;
		grid-template-columns: 25% 50% 25%;
		grid-template-rows: auto auto auto;
		grid-template-areas: 'title links info'
							'... ... ...'
							'demos demos nav';
	}
	.frame__title {
		margin: 0;
		grid-area: title;
	}
	.frame__demos {
		margin: 0;
		grid-area: demos;
		justify-self: start;
		align-self: end;
	}
	.frame__links {
		grid-area: links;
		padding: 0;
		justify-self: center;
	}
	.frame__info {
		grid-area: info;
		justify-self: end;
   		align-self: start;
	}
	.slides-nav {
		grid-area: nav;
		justify-self: end;
		align-self: end;
	}
	.frame a,
	.frame button {
		pointer-events: auto;
	}
	.slideshow {
		height: 100vh;
	}
	.slides-nav {
		margin-bottom: -1rem;
	}
	.slides-nav__index {
		margin-right: -2rem;
	}
	.slideshow {
		background: var(--color-bg);
	}

}
