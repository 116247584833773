.navigation-mega {
    background-color: color(primary);

    .navigation-inner {
        //
    }

    .logo {
        width: auto;
        height: 50px;
    }

    .menu-toggle {
        transition: 0.5s all ease-in-out;
    }

    .under-nav {
        width: 100%;
        background-color: #38b1d9;

        .navigation-items {
            a {
                color: color(secondary);
                padding: 0 1rem;
            }
        }
    }

    .mega-menu-inner {
        background-color: color(mega-menu);
        position: absolute;
        top: 194px;
        left: 0;
        width: 100vw;
        display: flex;
        justify-content: space-between;
        padding: 3rem 0;

        .menu-inner-item {
            max-width: 33%;
        }
    }
}




@include MQ(M) {
    //
    .navigation-mega {
        // .Navigation styles for Mobile

        .logo {
            height: 40px;
            width: auto;
            margin: auto;
        }

        .mega-menu-inner {  
            position: relative;
            top: 10px;
            width: 100%;
            justify-content: space-between;
            padding: 3rem 0;
    
            .menu-inner-item {
                max-width: 100%;
                width: 100%;
            }
        }

        .menu-toggle {
            position: absolute;
            top: 18px;
            left: 20px;
            width: 50px;
            height: 50px;
            flex-flow: row wrap;
            align-items: center;
            justify-content: center;
            z-index: 1100;
        
            .bar {
            display: block;
            width: 30px;
            height: 2px;
            background: color(secondary);
            transition: 0.5s all ease-out;
        
            &:not(:last-of-type) {
                margin-bottom: 5px;
            }
            }
        }
        
        .toggle-open {
            position: fixed !important;
            
            .bar {
            &:nth-child(3) {
                display: none;
            }
        
            &:nth-child(1) {
                transform: rotate(45deg);
            }
        
            &:nth-child(2) {
                transform: rotate(-45deg);
            }
        
            &:nth-child(1),
            &:nth-child(2) {
                margin-bottom: -2.5px;
                transition: 0.5s all ease-out;
            }
            }

            .open {
                background-color: white;
            }
        }

        
    }
}


@include MQ(S) {
    .navigation-mega {
        // Navigation styles for small screens
        padding: 0 10px;

        .logo {
            height: auto;
            width: 66%;
            margin: auto;
        }
    }
}

@include MQ(XS) {
    //
}


