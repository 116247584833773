.panels {
    //
    .panel {
        display: flex;
        align-items: center;

        &:nth-of-type(odd) {
            padding: 1rem 1rem 1rem 0;
        }

        &:nth-of-type(even) {
            padding: 1rem 0 1rem 1rem;
        }

        p, h1, h2, h3, h4 {
            margin-bottom: 1rem;
        }
    }
}

@include MQ(M) {
    .panels {
        //

        .panel {
            width: 100% !important;
            text-align: center;
            padding: 10px !important;
        }
    }
}