.footer {
    background-color: white;
    padding-top: 2rem;
    padding-left: 60px;
    padding-right: 60px;
    margin-top: 2rem;
    z-index: 10;
    position: relative;
    color: black;

    .footer-header {
        color: color(secondary);
        margin-bottom: 1rem;
    }

    .socials-banner {
        display: flex;
        width: 50%;
        justify-content: flex-end;

        p {
            font-size: 1.2rem;
        }
    }

    .footer-icon {
        font-size: 1.8rem;
        padding-left: 20px;
        color: color(secondary);

        &:hover {
            color: darken(color(secondary), 10);
        }
    }
    
    .top-footer {
        display: flex;
        padding-bottom: 2rem;
        justify-content: space-between;
    }

    .logo {
        width: auto;
        height: 35px;
    }

    .footer-col {
        width: 20%;
        
        a {
            color: black;
        }
    }

    .bottom-footer {
        p {
            font-size: 1rem;
        }
    }
}

@include MQ(M) {
    .footer {

        .top-footer {
            flex-wrap: wrap;

            .footer-col {
                margin-top: 1rem;
                width: 50%;
                text-align: center;
            }

            .info-col {
                width: 100% !important;
                text-align: center;
            }
        }
        
        .logo {
            margin: auto;
            height: 40px;
            width: auto;
            margin-bottom: 30px;
        }

        .logo-wrap {
            width: 100%;
        }

        .socials-banner {
            width: 100%;
            justify-content: center;
        }

        .last-col {
            width: 100% !important;
        }

    }
}

@include MQ(S) {
    .footer {
        .top-footer {
            .footer-col {
                width: 100%;
            }
        }

        .logo {
            width: 66%;
            height: auto;
        }
    }
}