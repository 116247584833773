.case-grid {
  .grid-item {
    width: calc(50% - 1rem);

    .case-info {
      opacity: 0;

      a {
        &:hover {
          color: white;
        }
      }

      &:hover {
        @apply text-white;

        opacity: 0.85;
      }
    }

    &:nth-child(1) {
      width: 100%;
    }
  }

  &:nth-of-type(odd) {
    .case-info {
      @apply bg-secondary;
    }
  }

  &:nth-of-type(even) {
    .case-info {
      @apply bg-secondary;
    }
  }
}
