.navigation-mega {
    .navigation-responsive {
        display: none;
    }

    @include MQ(M) {
        .navigation-responsive {
            display: flex;
            background-color: black;
            height: 100vh;
            width: 75vw;
            position: fixed;
            top: 0;
            left: -75vw;
            z-index: 99;
            transition: 0.5s all ease-out;
            overflow-y: scroll;

            .menu-inner-item {
                margin: 1rem 0;
            }
        }

        .nav-active {
            left: 0 !important;
            transition: 0.5s all ease-out;
        }
    }
}

