.accordion {
    border-bottom: 2px solid black;
    .accordion-body {
        height: 0;
        padding-top: 0;
        padding-bottom: 0;
        transition: all 0.5s ease;
        display: flex;
        align-items: center;
        min-height: 0;
        
        p {
            display: none;
        }
    }

    &:last-of-type {
        margin-bottom: 1rem;
    }
}

.accordion.open {
    .accordion-body {
        min-height: 100px;
        transition: all 0.5s ease;

        // p {
        //     display: block;
        // }
    }
}