.hero {
    height: 50vh;
    display: flex;
    align-items: center;
    background-size: cover;
    background-position: center;
    position: relative;
    background: none;

    .hero-inner {
        width: 50%;
    }

    p {
        margin-bottom: 2rem;
    }

    .button-primary {
        margin-top: 2rem;
    }
}

@include MQ(M) {
    .hero {
        //
        .hero-flex {
            justify-content: center !important;
        }

        .hero-inner {
            width: 80%;
        }
    }
}

@include MQ(S) {
    .hero {
        //

        .hero-flex {
            height: 100%;
        }

        .hero-inner {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

        }
    }
}

.home-hero {
    height: calc(100vh - 113px);

    .hero-flex {
        height: 70%;
    }

    .hero-inner {
        //
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 60%;
        z-index: 10;

        .home-hero-header {
            position: relative;
            z-index: 2;
            font-weight: 400;
        }

        .home-hero-sub {
            color: color(secondary);
            font-size: 2rem;
            margin-bottom: 2rem;
            position: relative;
            z-index: 2;
        }

        p {
          margin-bottom: 0;
        }

        h1 {
          margin-bottom: 2rem;
        }

        h2 {
          margin-bottom: 1rem;
        }
    }

    @include MQ(L) {
        .hero-inner {
            width: 60%;
        }
    }

    @include MQ(M) {
        .hero-inner {
            width: 100%;
        }
    }
}

.review-badge {
  z-index: 100;
}

.shape {
    position: absolute;
    max-width: 300px;
    height: auto;
    z-index: 1;
    -webkit-animation: float 3s ease-in-out infinite;
    animation: float 3s ease-in-out infinite;

    @include MQ(M) {
        max-width: 200px;
    }
}

.blue-shape {
    right: 100px;
    top: 55%;

    @include MQ(S) {
      top: 75%;
    }
}

.pink-shape {
    right: 0;
    top: 15%;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;

    @include MQ(S) {
        right: -100px;
        top: 55%;
    }
}

.fingerprint {
    top: 150px;
    left: -300px;
    max-width: 50vw;
    width: 50vw;
    opacity: 0.2;
    -webkit-animation: none;
    animation: none;
    z-index: -10 !important;

    @include MQ(S) {
      top: 0;
      left: -100px;
      max-width: 70vw;
      width: 70vw;
    }
}

.footer-hero {
    text-align: center;
    margin-bottom: 100px;
    margin-top: 200px;

    .footer-hero-header {
        font-size: 2.4rem;
        margin-bottom: 2rem;
        position: relative;
        z-index: 2;
    }

    .footer-hero-sub {
        color: color(muted-text);
        font-size: 1.4rem;
        margin-bottom: 5rem;
    }

    .hero-inner {
        width: 75%;
        margin: auto;
    }

    .pink-shape {
        right: -120px;
        top: -15%;
    }

    .blue-shape {
      right: -50px;
    }

    .review-badge {
      @apply mx-auto;

      width: 60%;
    }
}
