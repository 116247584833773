.carousel {
    //
    margin: 1rem auto;

    .carousel-item {
        height: 300px;
        width: 300px;
        display: flex;
        justify-content: center;
        object-fit: contain;
        background-size: cover;
        background-position: center;
        margin: 0.5rem;
    }
}