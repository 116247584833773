.home-section {
    width: 70%;
    margin: auto;

    hr {
        border-top: 2px solid color(secondary);
        width: 100px;
        margin: 1rem 0;
    }

    .text-block {
        padding: 4rem 0;
    }

    .home-section-text {
        width: 100%;

        h2,
        h3,
        h4 {
          margin-bottom: 1rem;
        }

        p {
            color: color(text);
            margin-bottom: 1.6rem;
            font-size: 1rem;

            @include MQ(M) {
                font-size: 1rem;
            }
        }

        a,
        strong {
          color: color(secondary);
        }
    }

    @include MQ(L) {
        width: 70%;
    }

    @include MQ(M) {
        width: 100%;
    }
}
