.navigation {
    .navigation-responsive {
        display: none;
    }

    @include MQ(M) {
        .navigation-responsive {
            display: flex;
            background-color: color(secondary);
            height: 100vh;
            width: 100vw;
            position: fixed;
            top: 0;
            left: 100vw;
            z-index: 200;
            transition: 1s all ease-in-out;
            overflow-y: scroll;
        }

        a {
            color: white;
        }

        .nav-active {
            left: 0 !important;
            transition: 1s all ease-in-out;
        }
    }
}
