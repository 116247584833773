body {
    font-family: 'Manrope', sans-serif;
}

h1,
h2,
h3,
h4 {
  font-weight: 600;
}

h1 {
    font-size: 1.8rem;
}

h2 {
    font-size: 1.6rem;
}

h3 {
    font-size: 1.4rem;
}

h4 {
    font-size: 1.2rem;
}

a {
    color: color(link);

    &:hover {
        color: color(link-hover);
    }
}

.muted-text {
    color: color(muted-text);
}
