// breakpoints

$XS:    400px;
$S:     520px;
$M:     768px;
$L:     1024px;
$XL:    1200px;

// media queries

@mixin MQ($canvas) {
  @if $canvas == S {
    @media only screen and (max-width: $S) { @content; }
  }

  @else if $canvas == M {
    @media only screen and (max-width: $M) { @content; }
  }

  @else if $canvas == L {
    @media only screen and (max-width: $L) { @content; }
  }

  @else if $canvas == XL {
    @media only screen and (max-width: $XL) { @content; }
  }
}

// Calculate Rem Functions
@function calculaterem($size) {
  $remSize: $size / 16px;

  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculaterem($size);
}

@keyframes float {
  0% {
    margin-top: 0;
  }

  50% {
    margin-top: -25px;
  }

  100% {
    margin-top: 0;
  }
}

@mixin float() {
  animation: float 3s ease-in-out infinite;
}