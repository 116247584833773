.icon-block {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;

    .icon-single {
        width: 50%;
        margin-bottom: 4rem;
        padding-right: 2rem;

        @include MQ(M) {
            width: 100%;
            text-align: center;
        }
    }

    .icon-block-icon {
        height: 100px;
        width: auto;
        margin-bottom: 2rem;

        @include MQ(M) {
            margin: auto;
            margin-bottom: 2rem;
        }
    }

    .icon-block-header {
        color: color(secondary);
        margin-bottom: 2.2rem;
        font-weight: 600;
    }

    p,
    a {
        font-weight: 500;
        padding: 0.25rem 0;
        color: color(muted-text);
        display: block;
    }
}
