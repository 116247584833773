.error-styles {
    color: red;
    font-weight: bold;
    padding: 1rem 0;
}

.contact-icon {
    font-size: 1.4rem;
    padding-right: 10px;
    color: color(secondary);
}

.contact-circle-img {
    border: 4px solid #EAEAEA;
    border-radius: 100%;
    margin-right: 30px;
}

.contact-form-header {
    font-size: 2rem;
    text-align: center;
}

.contact-form-sub-header {
    text-align: center;
    font-size: 1.6rem;
}

.contact {
    margin-top: 60px;

    input,
    textarea {
        border: 1px solid #707070 !important;
    }
}

form {
    width: 75%;

    @include MQ(L) {
        width: 80%;
    }

    @include MQ(M) {
        width: 100%;
    }
}

.Honeypot552,
#honeypotParam522 {
    display: none !important;
}