.case-study {
    .fingerprint {
        left: unset !important;
        right: -200px !important;
        transform: rotate(-45deg);
        opacity: 0.25;
    }
}

.case-study-card {
    width: 85%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    display: flex;
    align-items: flex-end;
    margin-bottom: 40px;
    position: relative;
    z-index: 3;


}

.case-study-card-wrap {
    height: 400px;

    @include MQ(M) {
        height: 400px !important;
    }

    &:nth-of-type(1) {
        height: 650px;
    }

    &:nth-of-type(2) {
        height: 350px;
    }

    &:nth-of-type(3) {
        height: 350px;
    }

    &:nth-of-type(4) {
        height: 650px;
        margin-top: -300px;

        @include MQ(M) {
            margin-top: 0 !important;
        }
    }
}
.case-study-image {
    height: 500px;
    width: 40%;
    object-fit: cover;
}
