.testimonial {
    margin: 1rem 0;
    position: relative;

    .customer-name {
        color: black;
        position: relative;
        z-index: 3;
    }

    .testimonial-text {
        color: color(muted-text);
        font-size: 1.1rem;
    }

    .quote {
        position: absolute;
        font-size: 50px;
        color: #970051;
        top: -60px;
        left: -30px;
        transform: rotate(200deg);

        @include MQ(M) {
            top: -50px;
            left: -10px;
        }
    }
}

@include MQ(S) {
    .testimonial {
        margin: 1rem 15px;
    }
}