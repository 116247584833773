//
.container-fluid {
    padding: 0 60px;

    @include MQ(L) {
        padding: 0 60px;
    }

    @include MQ(M) {
        padding: 0 40px;
    }

    @include MQ(S) {
        padding: 0 30px;
    }
}

.vh-60{
    height: 60vh;
}

.color-second {
    color: color(secondary);
}

.font-weight-500 {
    font-weight: 500;

    p {
        font-weight: 500;
    }
}
