.navigation {
    background-color: transparent;
    padding: 0 60px;

    .navigation-inner {

        .navigation-items {
            justify-content: flex-end;

            a {
                font-weight: 700;
            }
        }
    }

    .logo-outer {
        height: 50px !important;
    }

    .logo {
        width: auto;
        height: 36px;
    }

    .menu-toggle {
        transition: 0.5s all ease-in-out;
    }
}

@include MQ(L) {
    .navigation {
        //
        .navigation-inner {
            //

            .navigation-items {
                width: 75%;
                padding: 0 1rem;
            }
        }
    }
}

@include MQ(M) {
    //
    .navigation {
        // .Navigation styles for Mobile

        .logo {
            height: 40px;
            width: auto;
        }

        .menu-toggle {
            width: 50px;
            height: 50px;
            flex-flow: row wrap;
            align-items: center;
            justify-content: center;
            z-index: 1100;

            .bar {
            display: block;
            width: 30px;
            height: 2px;
            background: black;
            transition: 1s all ease-in-out;

            &:not(:last-of-type) {
                margin-bottom: 5px;
            }
            }
        }

        .right {
          z-index: 1000;
        }

        .toggle-open {
          z-index: 10000;

            .bar {
            &:nth-child(3) {
                display: none;
            }

            &:nth-child(1) {
                transform: rotate(45deg);
            }

            &:nth-child(2) {
                transform: rotate(-45deg);
            }

            &:nth-child(1),
            &:nth-child(2) {
                margin-bottom: -2.5px;
                transition: 1s all ease-in-out;
            }
            }

            .open {
                background-color: white;
            }
        }


    }
}


@include MQ(S) {
    .navigation {
        // Navigation styles for small screens
        padding: 0 30px;

        .logo {
            height: auto;
            width: 66%;
        }
    }
}

@include MQ(XS) {
    //
}
