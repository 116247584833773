.btn {
  @apply my-2 p-4 font-bold rounded text-white;
}

.btn-primary,
.button-primary,
.button-secondary,
.hero-contact-button {
  @apply bg-secondary btn;
  border: 2px solid color(secondary);

  &:hover {
    background: none;
    border: 2px solid color(secondary);
    color: color(secondary) !important;
  }
}

.hero-contact-button {
  font-size: 1.2rem;
  z-index: 100;
}

.nav-button {
    background-color: #d7b377;
    color: white;
    border-radius: 14px;
}

.calltoactionbanner-button {
    @include MQ(M) {
        text-align: center;
        margin: auto;
        margin-top: 1rem;
        width: 200px;
    }
}

.outline-button {
    padding: 1rem 4rem 1rem 4rem;
    background: transparent;
    border: 3px solid color(secondary);
    border-radius: 5px;
    color: color(secondary);

    &:hover {
        color: white;
        background-color: color(secondary);
    }
}

.form-submit-btn {
    margin-left: 1rem;
    margin-right: 1rem;
    width: 100%;
    padding: 2rem;
    background-color: color(secondary);
    color: white;
    font-size: 2rem;
    font-weight: 500;
    cursor: pointer;

    @include MQ(M) {
        font-size: 1.2rem;
    }
}
