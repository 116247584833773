

@include MQ(M) {
    .text-block {
        text-align: center;
    }
}

.blogpost {
    p, h2, figure {
        margin-bottom: 1rem;
    }

    a {
        color: color(imaginaire-purp);
    }
}