.cta {
  background: color(slate);
  overflow: hidden;

  h2,
  h3 {
    color: white;
    margin-bottom: 2rem;
    font-size: 3rem;
  }

  p {
    color: color(light-grey);
    font-size: 1.4rem;
  }

  a {
    display: inline-block;
    color: white;
    margin-top: 4rem;
    padding: 1rem 1.6rem;
    border: 1px solid white;

    &::after {
      font-family: FontAwesome, sans-serif;
      content: '\f105';
      margin-left: 1rem;
    }

    &:hover {
      background: white;
      border-color: white;
      color: color(slate);
    }
  }

  .fingerprint {
    position: absolute;
    z-index: 0 !important;
  }
}
